import { default as conditions_45generales_45utilisationf3X9JOzVLMMeta } from "/Users/ppl/Documents/_/live-display/public/pages/conditions-generales-utilisation.vue?macro=true";
import { default as conditions_45generales_45ventenQPuk4RadlMeta } from "/Users/ppl/Documents/_/live-display/public/pages/conditions-generales-vente.vue?macro=true";
import { default as contact8AFqdF2B36Meta } from "/Users/ppl/Documents/_/live-display/public/pages/contact.vue?macro=true";
import { default as indexVuaqsvlbrEMeta } from "/Users/ppl/Documents/_/live-display/public/pages/index.vue?macro=true";
import { default as politique_45confidentialite8ZRI65SwxvMeta } from "/Users/ppl/Documents/_/live-display/public/pages/politique-confidentialite.vue?macro=true";
export default [
  {
    name: "conditions-generales-utilisation",
    path: "/conditions-generales-utilisation",
    component: () => import("/Users/ppl/Documents/_/live-display/public/pages/conditions-generales-utilisation.vue")
  },
  {
    name: "conditions-generales-vente",
    path: "/conditions-generales-vente",
    component: () => import("/Users/ppl/Documents/_/live-display/public/pages/conditions-generales-vente.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/Users/ppl/Documents/_/live-display/public/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/ppl/Documents/_/live-display/public/pages/index.vue")
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    component: () => import("/Users/ppl/Documents/_/live-display/public/pages/politique-confidentialite.vue")
  }
]