<template>
  <nuxt-link
    :to="link"
    :class="classList"
    class="px-3.5 py-2.5 font-semibold rounded-md text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
  >
    {{ label }}
  </nuxt-link>
</template>

<script setup lang="ts">
import type { ButtonPrimaryProps } from 'types/button'

const { label, link, outline } = defineProps<ButtonPrimaryProps>()

const classList = computed(() => {
  if (outline) {
    return 'text-red-600 ring-1 ring-inset ring-red-200 hover:ring-red-300'
  }

  return 'bg-red-600 text-white shadow-sm hover:bg-red-500'
})
</script>
