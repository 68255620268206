import revive_payload_client_xkNYaWh646 from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Ug99t40Ukr from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_A8TcqtY0sJ from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_4BdHTyt9OS from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.26.0_vite@5.4.11_@types+node@22.9.0_sass@1.80._bw4vanagiuzlx33shlavy5wr3y/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_z9yUp3zoLT from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ObYYuTl594 from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uz6mqlMTMS from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ziTxs0kRnZ from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/ppl/Documents/_/live-display/public/.nuxt/components.plugin.mjs";
import prefetch_client_F4mwAnVvR3 from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import firebase_MrRCoKgj2U from "/Users/ppl/Documents/_/live-display/shared/plugins/firebase.ts";
import airbrake_H5MbEqRsNX from "/Users/ppl/Documents/_/live-display/shared/plugins/airbrake.ts";
import aos_u8RxmzeSAw from "/Users/ppl/Documents/_/live-display/public/plugins/aos.ts";
export default [
  revive_payload_client_xkNYaWh646,
  unhead_Ug99t40Ukr,
  router_A8TcqtY0sJ,
  _0_siteConfig_4BdHTyt9OS,
  payload_client_z9yUp3zoLT,
  navigation_repaint_client_ObYYuTl594,
  check_outdated_build_client_uz6mqlMTMS,
  chunk_reload_client_ziTxs0kRnZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_F4mwAnVvR3,
  firebase_MrRCoKgj2U,
  airbrake_H5MbEqRsNX,
  aos_u8RxmzeSAw
]