export const appName = 'Live Display'

export const appLogoAlt = `Logo ${appName}`
export const appLogoUrl = '/images/logo.webp'

export const appTaglineShort = 'Écrans animés, clients captivés'
export const appTaglineLong = 'Animez vos écrans, captivez vos clients'

export const appDescriptionShort = `${appName} est le logiciel de diaporama pour vos écrans. Idéal pour présenter vos offres et nouveautés à vos clients.`
export const appDescriptionLong = appDescriptionShort + ' ' + 'Les infos qui défilent attirent l\'attention des clients pour une communication efficace.'
