<template>
  <nuxt-layout>
    <div class="px-6 pt-14 lg:px-8">
      <div class="text-center pt-24 sm:pt-32">
        <p class="text-base font-semibold text-red-600">
          {{ error?.statusCode }}
        </p>

        <h1 class="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
          {{
            error?.statusCode === 404
              ? 'Page introuvable'
              : 'Problème technique'
          }}
        </h1>

        <p class="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
          {{
            error?.statusCode === 404
              ? 'Désolé, nous n\'avons pas trouvé la page que vous recherchez.'
              : 'Désolé, nous avons rencontré un problème technique.'
          }}
        </p>

        <div class="mt-10 flex items-center justify-center gap-x-6">
          <button-primary
            link="/"
            label="Retourner à l'accueil"
            @click="clearError()"
          />

          <button-secondary
            link="/contact"
            label="Contacter le support"
            class="font-semibold"
          />
        </div>
      </div>
    </div>
  </nuxt-layout>
</template>

<script setup>
const error = useError()

useHead({
  title: `Oups - ${appName}`
})
</script>
